import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

export const Button = ({ size, text, onClick, type, url = '' }) =>
  url ? (
    <a
      className={`btn btn-${size} btn-${type}`}
      onClick={onClick}
      href={url}
      target="_blank"
      rel="noopener noreferrer">
      {text}
    </a>
  ) : (
    <button
      type="button"
      className={`btn btn-${size} btn-${type}`}
      onClick={onClick}>
      {text}
    </button>
  );

Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'secondary']),
  url: PropTypes.string,
};
