/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'gatsby';
import { Button } from '../Button/Button';
import logo from '../../images/logo.svg';
// import useClickOutside from '../../hooks/useClickOutside';

import './Navbar.scss';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [goingUp, setGoingUp] = useState(true);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (
        window.pageYOffset > 100 &&
        prevScrollY.current < currentScrollY &&
        goingUp
      ) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [goingUp]);

  useEffect(() => {
    const body = document.querySelector('.body-wrapper');
    if (isNavOpen) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  }, [isNavOpen]);

  const closeHamburger = useCallback(() => {
    setIsNavOpen(false);
  }, []);

  const renderDropdown = () => (
    <div className="dropdown-wrapper">
      <div className={`dropdown ${isDropdownOpen ? 'dropdown-open' : ''}`}>
        <a
          href="/#streamline"
          className="dropdown-item"
          onClick={closeHamburger}>
          Streamline all your meetings
        </a>
        <a
          href="/#centralize"
          className="dropdown-item"
          onClick={closeHamburger}>
          Centralize &amp; prioritize your tasks
        </a>
        <a href="/#zone" className="dropdown-item" onClick={closeHamburger}>
          Be in the zone
        </a>
      </div>
    </div>
  );

  const renderNavContent = () => (
    <div className={`nav container ${isNavOpen ? 'mobile' : 'animations'}`}>
      <div className="nav-left">
        <div className="nav-item nav-logo hidden-mobile">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div
          className="nav-item nav-dropdown"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <div className="wrap">
            Features
            <svg
              className={`chevron ${isDropdownOpen ? 'active' : ''}`}
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1L5 5L9 1"
                stroke="#0B0F27"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {renderDropdown()}
        </div>
        <a
          href="https://blog.aerotime.com/"
          className="nav-item"
          target="_blank"
          rel="noopener noreferrer">
          Blog
        </a>
        <a
          href="https://new.aerotime.com/"
          className="nav-item"
          target="_blank"
          rel="noopener noreferrer">
          Changelog
        </a>
      </div>
      <div className="nav-right">
        <a
          href="https://app.aerotime.ai/"
          className="nav-item hidden-mobile"
          target="_blank"
          rel="noopener noreferrer">
          Login
        </a>
        <div className="visible-mobile w-full">
          <Button
            size="sm"
            text="Login"
            type="secondary"
            url="https://app.aerotime.ai/"
          />
        </div>
        <Button
          size="sm"
          text="Get Started"
          type="primary"
          url="https://app.aerotime.ai/"
        />
      </div>
    </div>
  );

  return (
    <>
      <nav className={`header ${goingUp ? '' : 'nav-hidden'} `}>
        <div className={`mobile-nav ${isNavOpen ? 'isNavOpen' : ''}`}>
          <div className="nav-item nav-logo">
            <img src={logo} alt="" />
          </div>
          {/* <span
            className={`menu-btn ${isNavOpen ? 'open' : ''}`}
           > */}
          <div
            className={`hamburger ${isNavOpen ? 'open' : ''}`}
            onClick={() => {
              setIsNavOpen(!isNavOpen);
            }}>
            <span></span>
          </div>
          {/* </span> */}
        </div>
        {renderNavContent()}
      </nav>
    </>
  );
};

export default Navbar;
