import axios from 'axios';
import React, { useState, useCallback } from 'react';
import isEmail from 'validator/lib/isEmail';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState(null);

  const inputChangeHandler = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (!email || !isEmail(email)) {
        setResponse({
          type: 'error',
          data: 'Please enter a valid email address.',
        });
        return;
      }

      setResponse(null);

      axios({
        url: process.env.GATSBY_SUBSCRIBE_URL,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ email }),
        method: 'POST',
      })
        .then(() => {
          setResponse({ type: 'success' });
        })
        .catch((err) => {
          setResponse({ type: 'error', data: err.toString() });
        });
    },
    [email],
  );

  return (
    <>
      <form onSubmit={submitHandler} className="inputBox">
        <input
          className="input"
          placeholder="Enter your email"
          type="email"
          value={email}
          onChange={inputChangeHandler}
        />
        <button type="submit" className="btn btn-primary">
          Subscribe
        </button>
      </form>
      {response?.type === 'error' && (
        <div style={{ margin: '-40px 0 40px', color: 'red' }}>
          {response.data}
        </div>
      )}
      {response?.type === 'success' && (
        <div style={{ margin: '-40px 0 40px', color: 'green' }}>Success!</div>
      )}
    </>
  );
};

export default SubscribeForm;
