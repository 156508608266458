import React from 'react';

import SubscribeForm from '../SubscribeForm/SubscribeForm';

import logo from '../../images/logo.svg';
import linkedin from '../../images/linkedin-icon.svg';
import twitter from '../../images/twitter-icon.svg';
import instagram from '../../images/instagram-icon.svg';
import './Footer.scss';

const Footer = () => (
  <div className="footer">
    <div className="footer-wrap container">
      <div className="col col-4 flex flex-col">
        <img loading="lazy" className="logo" src={logo} alt="" />
        <a href="mailto:hello@aerotime.com" className="link">
          hello@aerotime.com
        </a>
        <a href="tel:+14154187559" className="link">
          +1 (415) 418 - 7559
        </a>
        <p>San Francisco, CA</p>
      </div>
      <div className="links col col-3">
        <a
          href="https://blog.aerotime.com/"
          className="link"
          target="_blank"
          rel="noopener noreferrer">
          Blog
        </a>
        <a
          href="https://new.aerotime.com/"
          className="link"
          target="_blank"
          rel="noopener noreferrer">
          Changelog
        </a>
        <a
          href="http://help.aerotime.com/"
          className="link"
          target="_blank"
          rel="noopener noreferrer">
          Support
        </a>
      </div>
    </div>
    <div className="container">
      <SubscribeForm />
    </div>
    <div className="footer-section container">
      <div className="footer-section-wrap">
        <div className="trademark">
          &copy; {new Date().getFullYear()} All rights reserved.
        </div>
        <div className="left">
          <a
            href="/privacy-policy/"
            className="link"
            target="_blank"
            rel="noopener noreferrer">
            Privacy policy
          </a>
          <a
            href="/terms-of-use/"
            className="link"
            target="_blank"
            rel="noopener noreferrer">
            Terms of use
          </a>
        </div>
      </div>
      <div className="right">
        <a
          href="https://www.linkedin.com/company/aerotime"
          target="_blank"
          rel="noopener noreferrer">
          <img loading="lazy" className="icon" src={linkedin} alt="" />
        </a>
        <a
          href="https://twitter.com/AerotimeHQ"
          target="_blank"
          rel="noopener noreferrer">
          <img loading="lazy" className="icon" src={twitter} alt="" />
        </a>
        <a
          href="https://www.instagram.com/aerotimehq/"
          target="_blank"
          rel="noopener noreferrer">
          <img loading="lazy" className="icon" src={instagram} alt="" />
        </a>
      </div>
    </div>
  </div>
);

export default Footer;
